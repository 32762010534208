import { date } from '@/util/formatters';
import { format, isToday, isThisYear } from 'date-fns';
import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import { useBrand, useMobileView, useRouteQuery, useSort } from '@/composables';
import { getFullName } from '@/util/user';
import { useReportStatus } from '@/views/Brand/Applications/Report/_composables/use-report-status';
import FetchData from '@/components/DataProvider/FetchData.vue';
import { GenericTable, TableStyle } from '@/components/Table/__new__';
import { markReportAsStarred, unmarkReportAsStarred, } from '@/api/requests/reports';
import StatusToggler from '@/components/StatusToggler/StatusToggler.vue';
import { Checkbox, ListHeaderCheckbox } from '@/components/Inputs';
import { useSelection } from '@/composables/use-selection';
import { TablePagination } from '@/views/_components/Table';
import { TitleWithDescription } from '@/views/Brand/Applications/Report/_components/ReportTable/_components/title-with-description';
import { Debounce } from '@/constants/debounce';
import TableLoader from '@/views/_components/Table/TableLoader.vue';
import { genericTableRowSize } from '@/components/Table/__new__/_components/TableCellGeneric';
import { StatusButtonVariant } from '@/components/StatusToggler/StatusToggler.constants';
import { ExpandableCell } from './_components/expandable-cell';
import { Popover } from '@/components/Popover';
import { Icon } from '@/components/Icon';
import ShowingMenu from '@/components/EllipsisMenu/ShowingMenu.vue';
import { mdiChevronDown } from '@mdi/js';
import { useReportTableActions } from '@/views/Brand/Applications/Report/_components/ReportTable/_composables/use-report-table-actions';
import { isReportMessageUnread } from '@/views/Brand/Applications/Report/_components/ReportTable/_utils';
import { useUpdatesCounter } from '@/composables/use-updates-counter';
import { StarToggle } from '@/components/StarToggle';
// import NoResults from '@/views/_components/NoResults.vue';
// import AddReportButton from '@/views/Brand/Applications/Components/Sidebar/AddReportButton.vue';
export default defineComponent({
    components: {
        StarToggle,
        ListHeaderCheckbox,
        ShowingMenu,
        Icon,
        Popover,
        TableLoader,
        TablePagination,
        TitleWithDescription,
        Checkbox,
        StatusToggler,
        FetchData,
        TableDisplay: GenericTable(),
        ExpandableCell,
    },
    props: {
        query: {
            type: Object,
            default: () => ({}),
        },
        hideCheckbox: {
            type: Boolean,
            default: false,
        },
        hideEditAction: {
            type: Boolean,
            default: false,
        },
        update: {
            type: Number,
            default: 0,
        },
    },
    setup(props, ctx) {
        const { brand } = useBrand();
        const { updatesCounter, update } = useUpdatesCounter();
        const { getStatusOptions, REPORT_STATUS_COLORS, getActions, changeReportStatus, } = useReportStatus({ hideEditAction: props.hideEditAction, update }, ctx);
        const { isMobileView } = useMobileView();
        const { changeState, selectedEntries } = useSelection('id');
        const { getReportsListActions } = useReportTableActions({ selectedEntries, update, changeSelectedEntriesState: changeState }, ctx);
        const selectAll = ref(false);
        const onSelectAll = (results) => {
            changeState(results, selectAll.value);
        };
        const openPopup = () => {
            ctx.emit('open-popup');
        };
        const { routeQuery, updateQueryParameter } = useRouteQuery(ctx);
        const { sortConfig, sortBy, sort, setSortBy } = useSort();
        watch(routeQuery, (value) => {
            if (value.sortBy && sortBy !== value.sortBy)
                setSortBy(value.sortBy);
        });
        watch(sortBy, (value) => {
            updateQueryParameter({
                route: ctx.root.$route,
                parameter: 'sortBy',
                value: { value },
            });
        });
        const columns = computed(() => {
            const cols = [
                {
                    key: 'numberByBrand',
                    label: ctx.root.$t('brand-reports-list-view.no').toString(),
                    cellClass: 'flex items-center',
                    sortable: true,
                    hideDefaultContent: true,
                    hideHeader: isMobileView.value,
                    isNumber: true,
                },
                {
                    key: 'creator',
                    label: ctx.root.$t('brand-reports-list-view.creator').toString(),
                    gridColumn: isMobileView.value ? '1fr' : 'auto',
                    cellClass: 'flex items-center',
                    sortable: true,
                    hideDefaultContent: true,
                    hideHeader: isMobileView.value,
                },
                {
                    key: 'title',
                    label: ctx.root.$t('brand-reports-list-view.title').toString(),
                    cellClass: 'flex items-center',
                    gridColumn: '1fr',
                    hideInMobile: true,
                    hideDefaultContent: true,
                },
                {
                    key: 'places',
                    label: ctx.root.$t('brand-reports-list-view.places').toString(),
                    cellClass: 'flex items-center',
                    hideInMobile: true,
                    hideDefaultContent: true,
                },
                {
                    key: 'expandable',
                    cellClass: isMobileView.value
                        ? 'pl-0 pr-4'
                        : 'flex items-center justify-end',
                    hideDefaultContent: true,
                },
            ];
            if (!props.hideCheckbox) {
                cols.unshift({
                    key: 'checkbox',
                    label: '',
                    hideHeader: isMobileView.value,
                });
            }
            return cols;
        });
        const reportsQuery = computed(() => {
            return {
                ...routeQuery.value,
                brandId: brand.value.id,
                ...props.query,
            };
        });
        watch(() => props.update, () => {
            update();
        });
        const getReportClassName = (report) => {
            switch (true) {
                case report.isHighlighted:
                    return 'bg-yellow-light text-sm';
                case isReportMessageUnread(report):
                    return 'bg-white text-sm';
                default:
                    return 'bg-gray-lighter text-sm';
            }
        };
        const formatReportDate = (date) => {
            switch (true) {
                case isToday(date):
                    return format(date, 'HH:mm');
                case isThisYear(date):
                    return format(date, 'd MMM');
                default:
                    return format(date, 'd/MM/y');
            }
        };
        const getReportRowSize = () => {
            return isMobileView.value
                ? genericTableRowSize.LARGE
                : genericTableRowSize.SMALL;
        };
        const handleStarReport = async (report) => {
            report.isStarred
                ? await unmarkReportAsStarred({ reportIds: [report.id] })
                : await markReportAsStarred({ reportIds: [report.id] });
            update();
        };
        return {
            handleStarReport,
            formatReportDate,
            Debounce,
            TableStyle,
            isMobileView,
            REPORT_STATUS_COLORS,
            getStatusOptions,
            changeReportStatus,
            getActions,
            reportsQuery,
            columns,
            changeState,
            selectedEntries,
            selectAll,
            onSelectAll,
            sort,
            sortConfig,
            getFullName,
            getReportClassName,
            getReportRowSize,
            date,
            StatusButtonVariant,
            mdiChevronDown,
            getReportsListActions,
            isReportMessageUnread,
            updatesCounter,
            openPopup,
        };
    },
});
